import { pageConstants } from '../constants'

export const pageLoad = (payload) => {
  return {
    type: pageConstants.PAGE_LOAD,
    payload
  }
}

export const pageLoadSuccess = (payload) => {
  return {
    type: pageConstants.PAGE_LOAD_SUCCESS,
    payload
  }
}

export const pageLoadFailure = (payload) => {
  return {
    type: pageConstants.PAGE_LOAD_FAILURE,
    payload
  }
}

export const pageReset = () => {
  return {
    type: pageConstants.PAGE_RESET
  }
}
