import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import { eventLoadSuccess, eventLoadFailure } from '../actions'
import { uiHelper } from '../../helpers'

export function* eventLoadSaga(action) {
  try {
    let result = null
    result = yield axios.get(`${uris.event}/${action.payload}`)
    yield put(
      eventLoadSuccess({
        ...result.data,
        responseTime: result.headers['x-response-time']
      })
    )
  } catch (error) {
    yield put(eventLoadFailure(uiHelper.errorMessage(error)))
  }
}
