import { put } from 'redux-saga/effects'
import axios from '../../axios-conf'
import uris from '../../uris'
import { pageLoadSuccess, pageLoadFailure } from '../actions'
import { uiHelper } from '../../helpers'

export function* pageLoadSaga(action) {
  try {
    const result = yield axios.get(`${uris.page}/${action.payload}`)
    yield put(pageLoadSuccess(result.data))
  } catch (error) {
    yield put(pageLoadFailure(uiHelper.errorMessage(error)))
  }
}
