import { combineReducers } from 'redux'

import event from './event.reducer'
import page from './page.reducer'

const rootReducer = combineReducers({
  event,
  page
})

export default rootReducer
