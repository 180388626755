import { eventConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  list: null,
  init: false,
  responseTime: null
}

export default function event(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENT_LOAD:
      return {
        ...state,
        loading: true,
        list: null,
        responseTime: null,
        init: true
      }
    case eventConstants.EVENT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        responseTime: action.payload.responseTime
      }
    case eventConstants.EVENT_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case eventConstants.EVENT_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
