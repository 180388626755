import { uiConstants } from '../constants/ui.constants'

const errorMessage = (error) => {
  try {
    return error.response?.data?.message || error.message
  } catch {
    return 'Generic Error'
  }
}

const status = (code, expectedStatuses) => {
  if (expectedStatuses.includes(code)) {
    return uiConstants.colors.green
  }

  if (code < 200) {
    return uiConstants.colors.purple
  }
  if (code >= 200 && code < 300) {
    return uiConstants.colors.cyan
  }
  if (code >= 300 && code < 400) {
    return uiConstants.colors.yellow
  }
  if (code >= 400 && code < 500) {
    return uiConstants.colors.orange
  }
  if (code >= 500 && code < 600) {
    return uiConstants.colors.red
  }
  return uiConstants.colors.grey
}

export const uiHelper = {
  status,
  errorMessage
}
