import { takeEvery } from 'redux-saga/effects'
import { eventConstants, pageConstants } from '../constants'

import { eventLoadSaga } from './event.sagas'
import { pageLoadSaga } from './page.sagas'

export function* watchEvent() {
  yield takeEvery(eventConstants.EVENT_LOAD, eventLoadSaga)
}

export function* watchPage() {
  yield takeEvery(pageConstants.PAGE_LOAD, pageLoadSaga)
}
