export const uiConstants = {
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  dateTimeSecFormat: 'YYYY-MM-DD HH:mm:ss',
  hourFormat: 'HH:mm',
  dayOfWeek: '0',
  timeToDateInput: 'YYYY-MM-DD',
  timeToDateLong: 'YYYY MMM DD',
  year: 'YYYY',
  month: 'MM',
  monthWord: 'MMM',
  monthWordYear: 'MMM YYYY',
  day: 'DD',
  regions: [
    {
      value: 'europe-north1',
      label: 'Finland (europe-north1)'
    },

    {
      value: 'europe-west9',
      label: 'Paris (europe-west9)'
    },
    {
      value: 'europe-southwest1',
      label: 'Madrid (europe-southwest1)'
    },
    {
      value: 'europe-central2',
      label: 'Warsaw (europe-central2)'
    }
  ],
  colors: {
    green: 'Green',
    yellow: 'Yellow',
    orange: 'Orange',
    red: 'Red',
    purple: 'Purple',
    grey: 'Grey',
    cyan: 'Cyan'
  }
}
