import { pageConstants } from '../constants'

const initialState = {
  loading: false,
  error: null,
  config: null,
  init: false
}

export default function page(state = initialState, action) {
  switch (action.type) {
    case pageConstants.PAGE_LOAD:
      return {
        ...state,
        loading: true,
        config: null,
        init: true
      }
    case pageConstants.PAGE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        config: action.payload
      }
    case pageConstants.PAGE_LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case pageConstants.PAGE_RESET:
      return {
        ...initialState
      }
    default:
      return state
  }
}
