import { eventConstants } from '../constants'

export const eventLoad = (payload) => {
  return {
    type: eventConstants.EVENT_LOAD,
    payload
  }
}

export const eventLoadSuccess = (payload) => {
  return {
    type: eventConstants.EVENT_LOAD_SUCCESS,
    payload
  }
}

export const eventLoadFailure = (payload) => {
  return {
    type: eventConstants.EVENT_LOAD_FAILURE,
    payload
  }
}

export const eventReset = () => {
  return {
    type: eventConstants.EVENT_RESET
  }
}
