import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import { eventLoad, pageLoad } from '../../redux/actions'
import css from './Layout.module.scss'

import PageLoader from '../PageLoader/PageLoader'
import Logo from '../../assets/logo_horizontal.svg'
import ConditionalWrap from '../ConditionalWrap/ConditionalWrap'

const Layout = ({ event, page }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const { hostname } = window.location
    let name = hostname
    if (hostname.endsWith('nuketown.io')) {
      name = hostname.split('.')[0]
    }

    if (process.env.REACT_APP_DEV === 'true') {
      name = 'anomaleet'
    }

    if (!page.init) {
      dispatch(pageLoad(name))
    }
  }, [page.init, dispatch])

  useEffect(() => {
    if (page.config && !event.init) {
      window.document.title = page.config.name + ' - NukeTown.io'
      window.document.head.children.namedItem('description').content =
        page.config.name + ' - monitored by NukeTown.io'
      dispatch(eventLoad(page.config.url))
    }
  }, [event.init, dispatch, page.config])

  if (!page.config) {
    return <PageLoader message={page.error} />
  }

  return (
    <React.Fragment>
      <div
        className={css.HeaderContainer}
        style={{ background: page.config.header }}
      >
        <div className={css.Header}>
          <ConditionalWrap
            condition={page.config.website}
            wrap={(wrappedChildren) => (
              <a href={page.config.website} target={'_blank'} rel='noreferrer'>
                {wrappedChildren}
              </a>
            )}
          >
            <img src={page.config.logo} alt={page.config.name} />
          </ConditionalWrap>

          {/*  */}
        </div>
      </div>
      <main className={css.MainContainer}>
        <div className={css.Main}>
          <Outlet />
        </div>
      </main>
      <div className={css.Footer}>
        &copy;{new Date().getFullYear()}{' '}
        {page.config.copyright || 'NukeTown.io'}
        <a href='https://nuketown.io' target='_blank' rel='noreferrer'>
          <img src={Logo} alt='NukeTown' className={css.Logo} />
        </a>
        v.
        {document.head.querySelector('meta[name="build-version"]')['content']}
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return state
}

export default connect(mapStateToProps, {})(Layout)
