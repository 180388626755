import css from './PageLoader.module.scss'

import Logo from '../../assets/bomb.svg'

const PageLoader = ({ message }) => (
  <div className={css.LoaderBkg}>
    <div className={css.Loader}></div>
    <div className={css.Logo}>
      <img src={Logo} alt='NukeTown' />
    </div>
    <div className={css.Message}>{message}</div>
  </div>
)

export default PageLoader
