import moment from 'moment'
import { uiConstants } from '../constants/ui.constants'

const currentTime = () => {
  return moment().utc().unix()
}

const dateToFormat = (date) => {
  return moment.unix(date).format(uiConstants.dateTimeSecFormat)
}

const dateToFormatTime = (date) => {
  return moment.unix(date).format(uiConstants.hourFormat)
}

const dateToFormatDay = (date) => {
  return moment.unix(date).format(uiConstants.dateFormat)
}

const duration = (duration) => {
  if (duration === '-') return '-'

  const sec_num = parseInt(duration, 10)
  const days = Math.floor(sec_num / 3600 / 24)
  const hh = Math.floor(sec_num / 3600) % 24
  const mm = Math.floor(sec_num / 60) % 60
  const ss = sec_num % 60
  let res = ''
  if (days > 0) res += days + 'd '
  if (hh > 0) {
    if (res !== '') res += ', '
    res += `${hh}h`
  }
  if (mm > 0) {
    if (res !== '') res += ', '
    res += `${mm}m`
  }
  if (ss > 0) {
    if (res !== '') res += ', '
    res += `${ss}s`
  }
  if (res === '') res = '0s'
  return res
}

const daysAgo = (days) => {
  return moment()
    .utc()
    .add(days * -1, 'days')
    .startOf('day')
    .unix()
}

const dateToTimeStartDay = (input) => {
  if (input === '') return null
  const mOffset = moment().utcOffset()
  return moment(input, 'YYYY-MM-DD')
    .utc()
    .add(mOffset, 'minutes')
    .startOf('day')
    .unix()
}

const dateToTimeEndDay = (input) => {
  if (input === '') return null
  const mOffset = moment().utcOffset()
  return moment(input, 'YYYY-MM-DD')
    .utc()
    .add(mOffset, 'minutes')
    .endOf('day')
    .unix()
}

const setInputDate = (input) => {
  return moment.unix(input).format(uiConstants.timeToDateInput)
}

const dateToEpochStartDay = (input) => {
  if (input === '') return null
  const mOffset = moment().utcOffset()
  return moment(input).utc().add(mOffset, 'minutes').startOf('day').unix()
}

const dateToEpochEndDay = (input) => {
  if (input === '') return null
  const mOffset = moment().utcOffset()
  return moment(input).utc().add(mOffset, 'minutes').endOf('day').unix()
}

const fromNow = (input) => {
  if (input === '' || !input) return null
  return moment.unix(input).fromNow()
}

const weekRange = (date) => {
  const start = moment.unix(date).utc()
  const end = moment.unix(date).utc().add(6, 'days')

  const sY = start.year()
  const sM = start.month()
  const eY = end.year()
  const eM = end.month()

  let res = start.format(uiConstants.timeToDateLong) + ' to '
  if (sY !== eY) {
    res += end.format(uiConstants.year) + ' '
  }
  if (sM !== eM) {
    res += end.format(uiConstants.monthWord) + ' '
  }
  res += end.format(uiConstants.day)

  return res
}

const diffBetweenDates = (date1, date2) => {
  return moment.unix(date1).diff(moment.unix(date2), 'days')
}

const monthYear = (date) => {
  return moment.unix(date).format(uiConstants.monthWordYear)
}

export const timeHelper = {
  currentTime,
  dateToFormat,
  dateToFormatTime,
  dateToFormatDay,
  duration,
  daysAgo,
  dateToTimeStartDay,
  dateToTimeEndDay,
  setInputDate,
  dateToEpochStartDay,
  dateToEpochEndDay,
  fromNow,
  diffBetweenDates,
  weekRange,
  monthYear
}
