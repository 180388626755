import axios from 'axios'
import uris from './uris'

const axiosInstance = axios.create()

axiosInstance.defaults.baseURL = uris.apiBase
axiosInstance.defaults.withCredentials = true
axiosInstance.defaults.timeout = 30000

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      Accepted: 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0'
    }
    return config
  },
  (error) => Promise.reject(error)
)

export default axiosInstance
